<template>
  <section class="skills-marquee-container mt-[20svh]" ref="skillsSection">
    <h2 class="skills-title">> Tech Skills</h2>
    <div class="skills-marquee" ref="skillsMarquee">
      <ul class="skills-marquee-inner" ref="marqueeInner">
        <li v-for="(skill, index) in skills" :key="index" class="skill-card">
          <img :src="skill.icon" :alt="skill.name" class="skill-icon" />
          <h3 class="skill-name">{{ skill.name }}</h3>
        </li>
      </ul>
      <ul class="skills-marquee-inner" ref="marqueeInner">
        <li v-for="(skill, index) in skills" :key="index" class="skill-card">
          <img :src="skill.icon" :alt="skill.name" class="skill-icon" />
          <h3 class="skill-name">{{ skill.name }}</h3>
        </li>
      </ul>
      <ul class="skills-marquee-inner" ref="marqueeInner">
        <li v-for="(skill, index) in skills" :key="index" class="skill-card">
          <img :src="skill.icon" :alt="skill.name" class="skill-icon" />
          <h3 class="skill-name">{{ skill.name }}</h3>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
// import { gsap } from "gsap";

export default {
  name: "SkillsMarquee",
  setup() {
    const skillsSection = ref(null);
    const skillsMarquee = ref(null);
    const marqueeInner = ref(null);

    const skills = ref([
      {
        name: "JavaScript",
        description:
          "Experienced in ES6+ syntax and modern JavaScript frameworks.",
        icon: "https://img.icons8.com/?size=100&id=106036&format=png&color=000000",
      },
      {
        name: "Vue.js",
        description: "Proficient in building scalable SPAs with Vue.js.",
        icon: "https://img.icons8.com/?size=100&id=dzfo6UeXW9h7&format=png&color=000000",
      },
      {
        name: "React",
        description: "Proficient in building scalable SPAs with React",
        icon: "https://img.icons8.com/?size=100&id=58811&format=png&color=000000",
      },
      {
        name: "TypeScript",
        description:
          "Skilled in using TypeScript for type-safe JavaScript development.",
        icon: "https://img.icons8.com/?size=100&id=Xf1sHBmY73hA&format=png&color=000000",
      },
      {
        name: "Tailwind",
        description:
          "Expert in crafting responsive and modern UI with CSS and Tailwind CSS.",
        icon: "https://img.icons8.com/?size=100&id=x7XMNGh2vdqA&format=png&color=000000",
      },
      {
        name: "Git & GitHub",
        description: "Experienced with version control using Git and GitHub.",
        icon: "https://img.icons8.com/?size=100&id=GBrEaWeGmkC0&format=png&color=000000",
      },
      {
        name: "Node.js",
        description: "Proficient in building backend services with Node.js.",
        icon: "https://img.icons8.com/?size=100&id=hsPbhkOH4FMe&format=png&color=000000",
      },
      {
        name: "Python",
        description: "Scripting on Python and skills on Django & Flask.",
        icon: "https://img.icons8.com/?size=100&id=13441&format=png&color=000000",
      },
    ]);

    // const startMarquee = () => {
    //   const marqueeWidth = marqueeInner.value.scrollWidth / 2;

    //   gsap.to(marqueeInner.value, {
    //     x: -marqueeWidth,
    //     duration: 20,
    //     ease: "none",
    //     repeat: -1,
    //     onRepeat: () => {
    //       gsap.set(marqueeInner.value, { x: 0 });
    //     },
    //   });
    // };

    // onMounted(() => {
    //   startMarquee();
    // });

    return {
      skillsSection,
      skillsMarquee,
      marqueeInner,
      skills,
    };
  },
};
</script>

<style scoped>
.skills-marquee-container {
  width: 100%;
  padding: 20px 0;
}

.skills-title {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  font-family: "Press Start 2P", sans-serif;
  margin-bottom: 2rem;
}

.skills-marquee {
  --outer-gap: 30px;
  gap: var(--outer-gap);
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-block: 1rem;
}

.skills-marquee:hover ul {
  animation-play-state: paused;
}

.skills-marquee-inner {
  display: flex;

  --gap: 30px;
  gap: var(--gap);
  align-items: center;
  will-change: transform;
  animation: slide 20s linear infinite;
}

.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 140px; /* Adjusted width for better fit */

  padding: 10px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  white-space: nowrap; /* Prevent text wrap */
  background: #ece6ce;
  font-family: "Press Start 2P", sans-serif;
  transition: all 0.3s ease-in-out;
}

.skill-card:hover {
  transform: scale(1.1);
  box-shadow: 6px 6px 0px #ed6b5b;
}

.skill-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
}

.skill-name {
  font-size: 11px; /* Adjusted font size */
  font-family: "Press Start 2P", sans-serif;
  color: #333;
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  overflow: hidden; /* Hide overflowed text */
}

body {
  background-color: #121212;
}

.skills-marquee-container {
  border-top: 2px solid #93c5fd;
  border-bottom: 2px solid #93c5fd;
  padding: 10px;
  background: #222;
}

.skills-title {
  color: #93c5fd;
  text-shadow: 2px 2px #333;
}

.skill-card:hover {
  transform: scale(1.1) translateY(-5px);
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(calc(-100% - var(--gap)));
  }
}
</style>
