<template>
  <div class="iframe-modal" @click.self="emitClose">
    <div class="modal-content" v-show="!isLoading">
      <span class="close" @click="emitClose">&times;</span>
      <iframe :src="url" frameborder="0" @load="iframeLoaded"></iframe>
    </div>
    <div v-if="isLoading" class="loading-overlay">
      <!-- Simple 8-bit style loading spinner -->
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  props: {
    url: String,
  },
  data() {
    return {
      isLoading: true, // Start with loading true when iframe src is set
    };
  },
  methods: {
    emitClose() {
      this.isLoading = true; // Reset loading state on close
      this.$emit("close");
    },
    iframeLoaded() {
      this.isLoading = false;
      // Start the enter animation once the iframe is fully loaded
      gsap.fromTo(
        this.$el,
        {
          opacity: 0,
          scale: 0.7,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 0.5,
          ease: "steps(8)",
        }
      );
    },
  },
};
</script>

<style scoped>
.iframe-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
  z-index: 30;
}

.modal-content {
  position: relative;
  width: 80%;
  height: 80%;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  /* styling for the close button */
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 50px;
  height: 50px;
  background-color: transparent;
  animation: spin 0.8s steps(8) infinite;
  border: 6px dashed #ed6b5b;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 640px) {
  iframe {
    top: 5%;

    width: 100%;
    height: 100%;
    border: 8px dashed #f9ab675e;
    border-radius: 1px;
  }
}
</style>
