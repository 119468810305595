<template>
  <section class="py-8 section-margins">
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
    />
    <link
      rel="stylesheet"
      href="https://unpkg.com/swiper/swiper-bundle.min.css"
    />

    <h2
      class="title flex justify-center text-center text-5xl md:text-6xl lg:text-8xl font-jersey pt-32 mb-4"
    >
      <span
        v-for="(char, index) in 'CHRISTIAN\'S WORKS'.split('')"
        :key="index"
        >{{ char }}</span
      >
    </h2>

    <section
      style="--gap: 2rem"
      class="flex flex-wrap gap-[--gap] p-12 max-w-[888px] mx-auto rounded bg-gray-800"
    >
      <div
        class="flex basis-[calc(40%_-_var(--gap))] grow max-md:order-2 items-center justify-center"
      >
        <p class="leading-7 tracking-wide">
          With a keen eye for detail and a creative approach influenced by my
          passion for tech in general; I like to build immersive and efficient
          web applications that meet diverse client needs and elevate user
          experiences. Committed to innovation, I am always eager to embrace
          challenging projects that require both technical prowess and inventive
          strategies.
        </p>
      </div>
      <picture
        class="basis-[calc(60%_-_var(--gap))] min-w-[20rem] grow max-md:min-w-full max-md:order-1 "
      >
        <img
          src="@/assets/setup-pic.webp"
          alt="nice setup"
          class="object-cover rounded-lg shadow-md h-full"
          loading="lazy"
        />
      </picture>
    </section>

    <p
      class="md:mx-16 my-4 pb-8 px-4 pt-16 text-white-cream font-press-start-2p text-2xl md:text-3xl text-center lg:text-left rp"
    >
      > Recent Projects
    </p>

    <!-- Swiper for mobile -->
    <template v-if="isMobile">
      <swiper
        class="block md:hidden"
        :slidesPerView="1.2"
        :spaceBetween="5"
        loop="true"
        :pagination="{ clickable: true }"
      >
        <swiper-slide v-for="project in projects" :key="project.id">
          <div
            class="rounded overflow-hidden shadow-lg p-4 cartoon-shadow bg-white-cream text-gray-800 w-72 flex flex-auto flex-col justify-between transform transition-transform duration-300 ease-in-out hover:scale-105"
          >
            <div class="box-header">
              <h3 class="font-bold text-2xl">{{ project.name }}</h3>
              <h3 class="font-bold text-base mb-2">{{ project.type }}</h3>
            </div>
            <p class="text-base mb-4">{{ project.description }}</p>
            <div class="flex flex-wrap mb-4">
              <div
                class="tech-item"
                v-for="tech in project.technologies"
                :key="tech"
              >
                {{ tech }}
              </div>
            </div>
            <div class="pt-0 lg:pt-4 flex justify-center">
              <a
                v-if="project.liveUrl"
                :href="project.liveUrl"
                target="_blank"
                class="mt-4 bg-red-brick hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >Live Project</a
              >
              <a
                v-else
                class="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >Live Project</a
              >
              <a
                v-if="project.repoUrl"
                :href="project.repoUrl"
                target="_blank"
                class="mt-4 ml-4 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                >GitHub Repo</a
              >
              <a
                v-else
                class="mt-4 ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >Private Repo</a
              >
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </template>

    <!-- Grid for larger screens -->
    <template v-else>
      <div
        id="projects"
        class="grid justify-center grid-cols-[repeat(auto-fit_,_minmax(360px,_1fr))] gap-12 md:mx-16 my-4"
      >
        <div
          v-for="project in projects"
          :key="project.id"
          ref="projectCards"
          class="project-card rounded cartoon-shadow overflow-hidden shadow-lg p-4 lg:p-6 bg-white-cream text-gray-800 w-full flex flex-col justify-between"
        >
          <div class="box-header">
            <h3 class="font-bold text-2xl">{{ project.name }}</h3>
            <h3 class="font-bold text-base mb-2">{{ project.type }}</h3>
          </div>
          <p class="text-base mb-4">{{ project.description }}</p>
          <div class="flex flex-wrap mb-4">
            <div
              class="tech-item"
              v-for="tech in project.technologies"
              :key="tech"
            >
              {{ tech }}
            </div>
          </div>
          <div class="pt-0 lg:pt-4 flex justify-center">
            <a
              v-if="project.liveUrl"
              :href="project.liveUrl"
              target="_blank"
              class="mt-4 bg-red-brick hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >Live Project</a
            >
            <a
              v-else
              class="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >Live Project</a
            >
            <a
              v-if="project.repoUrl"
              :href="project.repoUrl"
              target="_blank"
              class="mt-4 ml-4 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
              >GitHub Repo</a
            >
            <a
              v-else
              class="mt-4 ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >Private Repo</a
            >
          </div>
        </div>
      </div>
    </template>

    <div class="flex justify-center py-4 mt-8">
      <router-link to="/resume" class="brick-button"> VIEW RESUME </router-link>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/pagination";

gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      projects: [],
      isMobile: false,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.fetchProjects();
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },
  mounted() {
    this.$nextTick(() => {
      this.animateTitle();
      this.animateCards();
    });
  },
  methods: {
    fetchProjects() {
      fetch("/projects.json")
        .then((response) => response.json())
        .then((data) => {
          this.projects = data;
        })
        .catch((error) => {
          console.error("Error loading projects data: ", error);
        });
    },
    checkMobile() {
      this.isMobile = window.innerWidth < 768;
    },
    animateTitle() {
      gsap.fromTo(
        ".title span",
        { opacity: 0, y: -20 },
        {
          opacity: 1,
          y: 0,
          delay: 0.7,
          duration: 0.3,
          stagger: 0.05,
          ease: "back.out(1.7)",
        }
      );
    },
    animateCards() {
      const cards = this.$refs.projectCards;
      if (cards && cards.length > 0) {
        cards.forEach((card) => {
          gsap.fromTo(
            card,
            { y: 100, opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 0.5,
              delay: 1.3,
              ease: "power3.out",
              scrollTrigger: {
                trigger: card,
                start: "top 80%",
                toggleActions: "play none none reverse",
              },
            }
          );
        });
      }
    },
  },
};
</script>

<style scoped>
.tech-item {
  background-color: #f1f5f9;
  color: #1a202c;
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 8px;
  font-size: 0.875rem;
}

.title span {
  display: inline-block;
  overflow: hidden;
  white-space: pre;
}

.button-view:hover {
  background-color: #c53030;
}

@media (max-width: 640px) {
  .tech-item {
    display: none;
  }
}

@media (max-width: 1024px) {
  .lg\:mx-72 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

img {
  transition: transform 0.5s, box-shadow 0.3s ease-in-out;
}

img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.bt-vr {
  padding: 0.5rem;
}
</style>
