<template>
    <div class="resume-section mb-8">
      <h3 class="text-xl  lg:text-2xl font-bold mb-4 font-press-start-2p">{{ title }}</h3>
      <div v-html="content"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ResumeSection",
    props: {
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .resume-section h3 {
    color: #ECE6CE; /* White text for section titles */
  }
  
  .resume-section div {
    color: #ECE6CE; /* Light grey text for content */
  }
  </style>
  