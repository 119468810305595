<template>
  <div v-if="!consentGiven" class="cookie-consent">
    <p>
      This website uses cookies to ensure you get the best experience on our
      website.
    </p>
    <div class="button-wrapper">
      <button @click="giveConsent" class="brick-button">Accept</button>
      <button @click="denyConsent" class="brick-button deny">Deny</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      consentGiven: false,
    };
  },
  mounted() {
    this.consentGiven = this.getConsentStatus();
  },
  methods: {
    getConsentStatus() {
      return localStorage.getItem("cookieConsentGiven") === "true";
    },
    giveConsent() {
      localStorage.setItem("cookieConsentGiven", "true");
      this.consentGiven = true;

      // Now TypeScript knows about `window.mouseflow`
      if (window.mouseflow && window.mouseflow.start) {
        window.mouseflow.start();
      } else {
        console.error("Mouseflow not loaded.");
      }
    },
    denyConsent() {
      this.consentGiven = true;
    }
  },
};
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #1a1e33;
  color: #ece6ce;
  text-align: center;
  padding: 10px;
  z-index: 1000;
  font-size: 1rem;
  font-family: "Jersey 10";
  box-shadow: black;
}

.cookie-consent > * {
  margin-inline: 1rem;
}

.brick-button {
  padding: 5px 10px;
  font-size: 11px;
  background-color: transparent;
  border: 1px solid #ece6ce;
}

.deny { 
  border: none;
}

.button-wrapper > * {
  margin-inline: 0.5rem;
}
</style>
