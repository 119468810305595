<template>
  <div class="p-4 fixed bottom-4 left-4 md:static md:ml-auto md:mr-0">
    <div
      id="back-to-top"
      @click="backToTop"
      class="shadow-black shadow-md bg-red-brick rounded w-12 h-12 flex items-center justify-center cursor-pointer transition-opacity duration-300"
      :class="{ 'opacity-0': !isVisible }"
    >
      <span class="text-white text-2xl">&#x2191;</span>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ScrollArrow",
  data() {
    return {
      lastScrollTop: 0,
      isVisible: false, // Controls the visibility of the arrow
    };
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkScroll);
  },
  methods: {
    checkScroll() {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > this.lastScrollTop) {
        // downscroll code
        if (st > 50) {
          // Only show the button if we've scrolled down more than 50px
          this.isVisible = true;
        }
      } else {
        // upscroll code
        this.isVisible = false;
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    backToTop() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: 0 } });
    },
  },
};
</script>
