<template>
  <div>
    <div id="floating-item-1" :style="styles.floatingItem1Style"></div>
    <div id="floating-item-2" :style="styles.floatingItem2Style"></div>
    <div id="floating-item-3" :style="styles.floatingItem3Style"></div>
  </div>
</template>

<script setup>
import { reactive, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import gsap from "gsap";
import sunImage from "@/assets/props/8bitsunweb.webp";
import cloud1Image from "@/assets/props/cloud1.png";
import cloud2Image from "@/assets/props/cloud2.png";

// Define reactive styles
const styles = reactive({
  floatingItem1Style: {
    backgroundImage: `url(${sunImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "35vh",
    height: "35vh",
    position: "absolute",
    zIndex: -2,
    opacity: 1,
  },
  floatingItem2Style: {
    backgroundImage: `url(${cloud1Image})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "32vh",
    height: "32vh",
    position: "absolute",
    top: "20vh",
    left: "60vh",
    zIndex: -3,
  },
  floatingItem3Style: {
    backgroundImage: `url(${cloud2Image})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "200px",
    height: "200px",
    top: "30%",
    left: "30%",
    position: "absolute",
    zIndex: -2,
  },
});

const route = useRoute();

// Update positions based on the route
function updatePositions(routeName) {
  // Define adjustments for each element and each route
  let adjustments = {
    home: [
      { top: "0%", left: "50%", opacity: "100%" }, // sun
      { top: "20%", left: "60%", opacity: "100%" }, // cloud1
      { top: "23%", left: "30%", opacity: "100%" }, // cloud2
    ],
    works: [
      { top: "0%", left: "10%", opacity: "100%" }, // sun
      { top: "7%", left: "60%", opacity: "20%" }, // cloud1
      { top: "45%", left: "5%", opacity: "75%" }, // cloud2
    ],
    resume: [
      { top: "2vh", left: "42%", opacity: "70%" }, // sun
      { top: "0%", left: "50%", opacity: "10%" }, // cloud1
      { top: "3%", left: "35%", opacity: "15%" }, // cloud2
    ],
    quizmegpt: [
      { top: "15%", left: "40%" }, // sun
      { top: "35%", left: "45%", opacity: "100%" }, // cloud1
      { top: "45%", left: "50%", opacity: "100%" }, // cloud2
    ],
  };

  // Retrieve adjustments for the current route
  const currentAdjustments = adjustments[routeName] || [
    { top: "0%", left: "50%" }, // Default for sun
    { top: "20%", left: "60%" }, // Default for cloud1
    { top: "30%", left: "30%" }, // Default for cloud2
  ];

  // Apply GSAP animations to each element individually
  gsap.to(styles.floatingItem1Style, {
    top: currentAdjustments[0].top,
    left: currentAdjustments[0].left,
    opacity: currentAdjustments[0].opacity,
    delay: 1,
    duration: 0.7,
  });
  gsap.to(styles.floatingItem2Style, {
    top: currentAdjustments[1].top,
    left: currentAdjustments[1].left,
    opacity: currentAdjustments[1].opacity,

    duration: 0.7,
  });
  gsap.to(styles.floatingItem3Style, {
    top: currentAdjustments[2].top,
    left: currentAdjustments[2].left,
    opacity: currentAdjustments[2].opacity,

    duration: 0.7,
  });
}

// Parallax effect setup
function setupParallax() {
  const updatePosition = (e) => {
    const x = e.clientX - window.innerWidth / 2;
    const y = e.clientY - window.innerHeight / 2;
    gsap.to("#floating-item-1", { x: x * 0.01, y: y * 0.01, duration: 0.5 });
    gsap.to("#floating-item-2", { x: x * 0.02, y: y * 0.02, duration: 0.5 });
    gsap.to("#floating-item-3", { x: x * 0.03, y: y * 0.03, duration: 0.5 });
  };

  window.addEventListener("mousemove", updatePosition);
}

onMounted(() => {
  setupParallax();
  updatePositions(route.name); // Initial position update
});

watch(
  () => route.name,
  (newRoute) => {
    updatePositions(newRoute);
  }
);
</script>

<style>
@media (max-width: 640px) {
  #floating-item-1 {
    width: 35% !important;
    height: 35% !important;
   
  }
  #floating-item-2, #floating-item-3 {
    display: none;
  }
}
</style>
