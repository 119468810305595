<template>
  <div id="app" class="text-white-cream relative">
    <div class="background-overlay"></div>
    <NavBar />
    <IframeModal :url="iframeUrl" v-if="showIframe" @close="closeIframe" />
    <div class="" :class="{ 'no-scroll': showIframe }">
      <transition
        name="fade"
        mode="out-in"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <router-view @open-iframe="openIframe" />
      </transition>
      <div class="section-margins">
        <ScrollArrow />
      </div>
    </div>
    <FloatingElements />
    <CookieConsent />
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import FloatingElements from "@/components/FloatingElements.vue";
import ScrollArrow from "@/components/ScrollArrow.vue";
import CookieConsent from "@/components/CookieConsent.vue";
import IframeModal from "./components/IframeModal.vue";
import { gsap } from "gsap";

export default {
  name: "App",
  components: {
    NavBar,
    FooterComponent,
    FloatingElements,
    ScrollArrow,
    CookieConsent,
    IframeModal,
  },
  data() {
    return {
      showIframe: false,
      iframeUrl: "",
    };
  },
  methods: {
    beforeEnter(el) {
      // Prepare the element with initial styles
      gsap.set(el, {
        opacity: 0,
        scale: 0.8,
        filter: "contrast(1.5) saturate(0)",
      });
    },
    enter(el, done) {
      // Multi-step animation for the 8-bit style entry
      const tl = gsap.timeline({
        onComplete: done,
      });

      // Initial quick scale up with color change
      tl.to(el, {
        opacity: 0,
        filter: "contrast(1.8) saturate(1.5)",
        duration: 0.2,
        ease: "steps(8)",
      });

      // Final adjustment to normal scale and full opacity
      tl.to(el, {
        opacity: 1,
        scale: 1,
        filter: "contrast(1) saturate(1)",
        duration: 0.3,
        ease: "steps(8)",
      });
    },
    leave(el, done) {
      // Multi-step animation for the 8-bit style exit
      const tl = gsap.timeline({
        onComplete: done,
      });

      // Begin with quick contraction and color change
      tl.to(el, {
        opacity: 0,
        scale: 0.9,
        filter: "contrast(1.8) saturate(1.5)",
        duration: 0.3,
        ease: "steps(8)",
      });

      // Complete the exit with scale down and fade out
      tl.to(el, {
        opacity: 0,
        scale: 0.8,
        filter: "contrast(1.5) saturate(0)",
        duration: 0.4,
        ease: "steps(8)",
      });
    },
  },
};
</script>

<style>
body {
  margin: 0;
  background-color: #484f70;
}

#app {
  position: relative;
  overflow: hidden;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/props/pixelbg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  z-index: -100;
  mix-blend-mode: color-dodge;
  opacity: 5%;
}

.FloatingElements {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.no-scroll {
  overflow: hidden;
}
</style>
