<template>
  <div>
    <section class="h-svh flex flex-col justify-between section-margins hero">
      <div class="wrapper-top my-auto">
        <h1
          class="text-7xl lg:text-8xl font-jersey pt-14 lg:pt-28 mt-32 text-left"
          ref="title"
        >
          CHRISTIAN D'ALBANO
        </h1>
        <h2
          class="text-xl font-mukta-vaani font-semibold text-left"
          ref="subtitle"
        >
          SOFTWARE DEVELOPER BASED IN ORLANDO, FL
        </h2>
        <p
          class="mt-8 text-left leading-7 tracking-wide text-caption max-w-4xl"
        >
          From out-of-the-box solutions to responsive and useful web
          applications, my love for development is deeply influenced by my
          passion to turn ideas into functional matter. I enjoy breaking down
          problems to bring life to impactful, user-based websites pixel by
          pixel.
        </p>
      </div>

      <div
        class="flex flex-col md:flex-row items-center justify-center custom-border rounded mx-auto max-w-xs"
      >
        <div
          class="bg-gray-900 opacity-80 p-4 icon-container flex justify-around w-full md:w-auto"
        >
          <a
            href="https://www.linkedin.com/in/chrisdalb/"
            target="_blank"
            class="icon-link"
          >
            <img
              class="icon"
              src="https://img.icons8.com/color/48/000000/linkedin.png"
              alt="Linkedin"
            />
          </a>
          <a
            href="https://github.com/chrisdalbano/"
            target="_blank"
            class="icon-link"
          >
            <img
              class="icon"
              src="https://img.icons8.com/color/48/000000/github--v1.png"
              alt="Github"
            />
          </a>
          <a
            href="https://twitter.com/chrisdalbano/"
            target="_blank"
            class="icon-link"
          >
            <img
              class="icon"
              src="https://img.icons8.com/color/48/twitterx--v2.png"
              alt="twitterx--v2"
            />
          </a>
        </div>
        <div class="p-4 text-center md:text-left">
          <p class=" font-jersey text-xl">Me. In the network.</p>
        </div>
      </div>
    </section>

    <section
      class="h-svh flex items-center justify-center mt-[20svh] section-margins"
    >
      <div class="about-section sm:my-2 md:my-8 lg:my-12">
        <div class="flex flex-col md:p-4 lg:p-10 text-caption" ref="aboutText">
          <h2
            class="mt-10 mb-8 text-white-cream font-press-start-2p text-2xl md:text-3xl"
          >
            > About Me
          </h2>
          <p class="mb-8 leading-7 tracking-wide">
            Creative Developer with a focus on crafting clean, maintainable code
            that bridges real-world challenges with digital solutions, enhancing
            user connectivity and accessibility.
          </p>
          <p class="mb-8 leading-7 tracking-wide">
            In previous roles, I've learned the best practices from Web
            Analytics experts, allowing me to ensure each project is engaging
            and strategically optimized for performance and efficiency.
            Committed to responsive design and advanced SEO strategies, I am
            equipped to tackle the next challenge with energy and precision,
            efficiently addressing user needs.
          </p>
          <p class="mb-8 leading-7 tracking-wide">
            I'm positive, fun, super motivated, and always willing to learn. I'm
            here to build positive relationships, find creative solutions to
            complex problems. I enjoy pair programming and collaborating with
            real people.
          </p>
          <div class="flex justify-end mb-8">
            <button
              class="brick-button"
              @click="scrollToProjects"
              ref="showMeButton"
            >
              TO PROJECTS
            </button>
          </div>
        </div>
        <div class="profile-card" ref="profileSec">
          <img
            :src="profilePic"
            alt="Profile Picture"
            class="profile-pic"
            ref="profilePic"
          />
        </div>
      </div>
    </section>

    <SkillsMarquee />

    <section
      class="mt-[20svh] flex flex-col my-auto section-margins"
      ref="projectsSection"
    >
      <h1 class="text-5xl md:text-6xl lg:text-8xl font-jersey">
        CHOOSE A WEB PROJECT
      </h1>
      <h2 class="text-base md:text-lg font-mukta-vaani mt-2 mb-8">
        These are only a few of my recent websites.
      </h2>
      <div
        class="text-gray-900 px-2 md:px-16 sm:px-4 lg:px-2 lg:text-2xl pb-10 lg:pb-16 flex-container max-w-[1400px]"
      >
        <div
          v-for="project in projects"
          :key="project.id"
          :ref="setProjectRef"
          :id="'card-' + project.id"
          class="project-card rounded-lg overflow-hidden cursor-pointer cartoon-shadow flex-container-child"
          @mouseenter="scaleProjectUp"
          @mouseleave="scaleProjectDown"
          @click="openModal(project.url)"
        >
          <div
            class="bg-cover h-32 lg:h-64 bg-center"
            :style="{ backgroundImage: 'url(' + project.image + ')' }"
          ></div>
          <div
            class="h-36 md:h-48 bg-white-cream flex items-center justify-center flex-col p-1 lg:p-4"
          >
            <p class="text-center text-gray-800 font-semibold font-jersey text-3xl tracking-wide">
              {{ project.title }}
            </p>
            <p class="text-center text-xs md:text-base lg:text-base mt-2">
              {{ project.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <router-link to="/works" class="brick-button"> VIEW MORE </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import party from "party-js";
import SkillsMarquee from "@/components/SkillsMarquee.vue";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "HomeView",
  data() {
    return {
      profilePic: require("@/assets/profile-pic-optimized.webp"),
      projects: [
        {
          id: 1,
          title: "QuizMeGPT",
          description:
            "An AI based Quiz Generator Web Application, using OpenAI's API ChatGPT, Firebase and lots of regex to generate interesting quizzes based on user prompt.",
          image: require("@/assets/qmgpt2.webp"),
          url: "https://quizmegpt.chrisdalbano.com",
        },
        {
          id: 2,
          title: "Aizhak Coffee",
          description:
            "A beautiful responsive specialty Full-Stack coffee shop website. Incorporates e-commerce elements, newsletter system, sidescrolling and dynamic elements.",
          image: require("@/assets/AizhakBanner.webp"),
          url: "https://aizhak.com",
        },
        {
          id: 3,
          title: "MTGen",
          description:
            "An AI powered Web Application by ChatGPT and DALL-E APIs in Flask to generate custom Magic The Gathering cards based on prompts. Deployed on Azure.",
          image: require("@/assets/magicthegenpic.webp"),
          url: "https://mtgen.chrisdalbano.com",
        },
        
        
      ],
      projectRefs: [],
    };
  },
  components: {
    SkillsMarquee,
  },
  computed: {
    gridColsClass() {
      const numProjects = this.projects.length;
      return `sm:grid-cols-1 md:grid-cols-2 grid-cols-${numProjects}`;
    },
    projectsLength() {
      return this.projects.length;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
    window.addEventListener("mousemove", this.handleMouseMove);

    this.$nextTick(() => {
      this.animateTitle();
      this.animateCards();
      this.animateAbout();
      this.animatePic();
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkScroll);
    window.removeEventListener("mousemove", this.handleMouseMove);

    ScrollTrigger.getAll().forEach((ST) => ST.kill());
  },
  methods: {
    openModal(url) {
      window.open(url, "_blank");
    },
    setProjectRef(el) {
      if (el) {
        this.projectRefs.push(el);
      }
    },
    scaleButtonUp() {
      gsap.to(this.$refs.showMeButton, {
        scale: 1.1,
        duration: 0.2,
        ease: "power1.out",
      });
    },
    scaleButtonDown() {
      gsap.to(this.$refs.showMeButton, {
        scale: 1,
        duration: 0.1,
        ease: "power1.out",
      });
    },
    scrollToProjects(event) {
      const navbarHeight = document.querySelector(".navbar")?.offsetHeight || 0;
      party.confetti(event.currentTarget, {
        shapes: ["square"],
      });
      const sectionOffsetTop =
        this.$refs.projectsSection.offsetTop - navbarHeight;
      gsap.to(window, {
        delay: 0.2,
        duration: 0.7,
        scrollTo: { y: sectionOffsetTop, autoKill: false },
        ease: "power3.inOut",
      });
    },
    animateTitle() {
      gsap.fromTo(
        this.$refs.title,
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          delay: 0.3,
          duration: 0.6,
          ease: "power3.out",
        }
      );
      gsap.fromTo(
        this.$refs.subtitle,
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          duration: 0.6,
          ease: "power3.out",
        }
      );
    },
    animateAbout() {
      gsap.fromTo(
        this.$refs.aboutText,
        { opacity: 0, y: 200 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: this.$refs.aboutText,
            start: "top 75%",
            toggleActions: "play none none none",
          },
        }
      );
    },
    animatePic() {
      gsap.fromTo(
        this.$refs.profileSec,
        { opacity: 1, y: 200 },
        {
          opacity: 1,
          y: 0,
          duration: 0.3,
          stagger: 0.2,
          ease: "ease.out",
          scrollTrigger: {
            trigger: this.$refs.aboutText,
            start: "top 50%",
            toggleActions: "play none none none",
          },
        }
      );
    },
    animateCards() {
      const cards = this.projectRefs;
      if (cards && cards.length > 0) {
        cards.forEach((card) => {
          gsap.fromTo(
            card,
            { y: 100, opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 0.5,
              ease: "power3.out",
              scrollTrigger: {
                trigger: card,
                start: "top 80%",
                toggleActions: "play none none reverse",
              },
            }
          );
        });
      }
    },
  },
};
</script>

<style scoped>
.flex-container {
  --n: v-bind(projectsLength);
  --gap: 1.5rem;
  display: flex;
  flex-wrap: wrap; /* this */
  gap: var(--gap);

}

.flex-container-child {
  /* Formula: max(400px, (100% - (N - 1) * gap)/N) */
  flex: max(
    400px,
    (100% - ((var(--n) - 1) * var(--gap))) / var(--n)
  ); /* and this */
}

.about-section {
  --gap: 10px;
  display: flex;
  flex-wrap: wrap;
  margin: rem;
  height: fit-content;
  gap: var(--gap);
}

.about-section > .profile-card {
  aspect-ratio: 16/9;
  flex: 1 0 calc(55% - var(--gap)); /* Flexible width */
  min-width: 20rem;
}

.about-section > .text-caption, .hero > .text-caption {
  flex: 1 0 calc(45% - var(--gap)); /* Flexible width */
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  line-height: 1.5;
}


@media (max-width: 640px) {
  .about-section > .profile-card {
    min-width: 100%;
  }
}

.icon-container {
  display: flex;
  justify-content: space-around;
}

.icon-link {
  transition: border 0.3s ease-in-out;
  border: 2px solid transparent;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.icon-link:hover {
  border: 2px dashed #fff;
}

.icon {
  filter: grayscale(100%);
}

.custom-border {
  border: 2px dashed transparent; /* Default transparent border */
  transition: all 0.5s ease;
}

.custom-border:hover {
  border-color: whitesmoke; /* Change border color on hover */
}

.profile-card {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  transition: transform 0.35s ease-in-out;
}

.profile-card:hover {
  transform: translateY(-10px); /* Add slight movement on hover */
}

.profile-card .profile-pic {
  transform: scale(1.1);
  transition: transform 0.35s ease-in-out;
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.profile-pic:hover {
  transform: scale(1.2); /* Add slight zoom on hover */
}

.dialog-box {
  overflow-x: hidden;
  background: #f0f0f0; /* Light grey background to make the text more readable */
  border: 1px solid #ccc; /* Subtle border */
}

.herosection {
  height: 100vh;
}

.swiper-slide-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (max-width: 640px) {
  .profile-pic {
    width: 100%;
    height: auto;
  }
}
</style>
