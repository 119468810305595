<template>
  <footer class="m-6 mt-4 sm:mb-16 lg:mb-4">
    <p class="flex justify-center text-center text-blue-300">
      Crafted with espresso-fueled coding sessions.
    </p>
    <p class="text-center text-yellow-tiger">
      © {{ currentYear }} Chrisdalbano.
    </p>
  </footer>
</template>

<script setup>
const currentYear = new Date().getFullYear();
</script>
