<template>
  <section class="py-8 section-margins">
    <h2 class="text-center text-5xl md:text-6xl lg:text-8xl font-jersey pt-32">
      CHRISTIAN'S RESUME
    </h2>
    <p
      class="text-center text-lg md:text-2xl font-mukta-vaani font-semibold mt-2"
    >
      EXPLORE MY STORY, VISUALIZE MY JOURNEY.
    </p>
    <div class="flex justify-center pt-4 pb-4">
      <a
        :href="pdfFile"
        target="_blank"
        class="brick-button"
        ref="downloadButton"
      >
        DOWNLOAD
      </a>
    </div>
    <div class="flex justify-center px-4">
      <div class="w-full lg:w-2/3 font-mukta-vaani mt-8">
        <ResumeSection
          v-if="resumeData"
          title="Summary"
          :content="resumeData.summary"
          ref="summarySection"
        />
        <ResumeSection
          v-if="resumeData"
          title="Technical Skills"
          :content="technicalSkills"
          ref="technicalSkillsSection"
        />
        <ResumeSection
          v-if="resumeData"
          title="Experience"
          :content="experience"
          ref="experienceSection"
        />
        <ResumeSection
          v-if="resumeData"
          title="Projects"
          :content="projects"
          ref="projectsSection"
        />
        <ResumeSection
          v-if="resumeData"
          title="Education"
          :content="education"
          ref="educationSection"
        />
        <ResumeSection
          v-if="resumeData"
          title="Additional Experience"
          :content="additionalExperience"
          ref="additionalExperienceSection"
        />
        <ResumeSection
          v-if="resumeData"
          title="Skills"
          :content="skills"
          ref="skillsSection"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import axios from "axios";
import pdfFile from "@/assets/Christian_DAlbano_Resume.pdf";
import ResumeSection from "@/components/ResumeSection.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ResumeView",
  components: { ResumeSection },
  data() {
    return {
      resumeData: null,
      pdfFile,
    };
  },
  computed: {
    technicalSkills() {
      if (!this.resumeData) return "";
      return Object.entries(this.resumeData.technical_proficiency)
        .map(
          ([key, value]) =>
            `<strong>${key
              .replace("_", " ")
              .replace(/\b\w/g, (c) => c.toUpperCase())}:</strong> ${value}`
        )
        .join("<br/>");
    },
    experience() {
      if (!this.resumeData) return "";
      return this.resumeData.experience
        .map(
          (exp) => `
        <p class="font-bold">${exp.position} @ ${exp.company} (${exp.dates})</p>
        <ul class="list-disc list-inside ml-4">
          ${exp.responsibilities
            .map((resp) => `<li class="leading-relaxed">${resp}</li>`)
            .join("")}
        </ul>
      `
        )
        .join("<br/>");
    },
    projects() {
      if (!this.resumeData) return "";
      return this.resumeData.projects
        .map(
          (project) => `
        <a href="${project.url}" target="_blank" class="font-bold text-red-brick transition-colors hover:text-red-800">${project.title}</a>
        <p class="leading-relaxed">${project.description}</p>
        <p><strong>Technologies:</strong> ${project.technologies}</p>
      `
        )
        .join("<br/>");
    },
    education() {
      if (!this.resumeData) return "";
      return this.resumeData.education
        .map(
          (edu) => `
        <li class="leading-relaxed">${edu.degree} - ${edu.institution}, ${edu.dates}</li>
      `
        )
        .join("");
    },
    additionalExperience() {
      if (!this.resumeData) return "";
      return this.resumeData.additional_experience
        .map(
          (addExp) => `
        <p class="font-bold">${addExp.position} @ ${addExp.company} (${
            addExp.dates
          })</p>
        <ul class="list-disc list-inside ml-4">
          ${addExp.responsibilities
            .map((resp) => `<li class="leading-relaxed">${resp}</li>`)
            .join("")}
        </ul>
      `
        )
        .join("<br/>");
    },
    skills() {
      if (!this.resumeData) return "";
      return this.resumeData.skills
        .map((skill) => `<li class="leading-relaxed">${skill}</li>`)
        .join("");
    },
  },
  mounted() {
    this.fetchResumeData();
  },
  methods: {
    fetchResumeData() {
      axios
        .get("/resume_data.json")
        .then((response) => {
          this.resumeData = response.data;
          this.$nextTick(() => {
            this.animateSections();
          });
        })
        .catch((error) => {
          console.error("Error fetching resume data:", error);
        });
    },
    animateSections() {
      const sections = [
        this.$refs.summarySection,
        this.$refs.technicalSkillsSection,
        this.$refs.experienceSection,
        this.$refs.projectsSection,
        this.$refs.educationSection,
        this.$refs.additionalExperienceSection,
        this.$refs.skillsSection,
      ];

      sections.forEach((section) => {
        if (section) {
          gsap.from(section.$el, {
            autoAlpha: 0,
            y: 50,
            duration: 1.5,
            ease: "power3.out",
            scrollTrigger: {
              trigger: section.$el,
              start: "top 90%",
              toggleActions: "play none none none",
            },
          });
        }
      });
    },
  },
};
</script>

<style scoped>
/* Additional styles for better readability and aesthetics */
body {
  background-color: #1a202c; /* Dark background for better contrast */
}

h2 {
  color: #ece6ce; /* White text for better contrast */
}

.text-justify {
  text-align: justify;
  color: #ece6ce; /* White text for better readability */
}

.bg-blue-mistery {
  background-color: #1a1e33; /* A more pleasant dark blue background */
}

.shadow-black {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.bg-red-brick {
  background-color: #e53e3e; /* Red color for buttons */
}

.hover\:bg-red-800:hover {
  background-color: #c53030; /* Darker red on hover */
}

.transition-transform {
  transition: transform 0.2s;
}

.transition-colors {
  transition: color 0.2s;
}

@media (max-width: 640px) {
  .text-center {
    text-align: center;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pt-32 {
    padding-top: 8rem;
  }
  .mt-2 {
    margin-top: 0.5rem;
  }
  .pt-4 {
    padding-top: 1rem;
  }
  .pb-4 {
    padding-bottom: 1rem;
  }
  .w-full {
    width: 100%;
  }
}
</style>
